import React, { useState, useEffect } from 'react';

import '../App.css';
import twitter from '../images/twitter.svg'
import medium from '../images/medium.svg'
import link from '../images/linkedin.svg'
import git from '../images/git.svg'
import fabio from '../images/web_fabio_profile.jpg'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import fire from '../components/Firebase'

function Home(){
    const [items, setItems] = useState();
    const [focus, setFocus] = useState();    

    useEffect(() => {
        if(focus == null){
            setFocus("apps")
            loadItems("apps", setItems);
        }
    })

    function toggleFocus(focus){

        var newFocus = ""

        if(focus == 'works'){
            newFocus = 'apps'
        }else if(focus == 'apps'){
            newFocus = 'ux'
        }else if(focus == 'ux'){
            newFocus = 'websites'
        } else if(focus == 'websites'){
            newFocus = 'articles'
        }else if(focus == 'articles'){
            newFocus = 'apps'
        }

        setFocus(newFocus)
        loadItems(newFocus);
    }


    function loadItems(focus) {
        fire
        .collection('items')
        .where('type','==', focus)
        .get().then((snap) => {

            console.log("loadItems")
            setItems(snap.docs)

        }).catch((err) => console.log(err))
    }

   return (
    <div className="App">
                    <body>
                        <img className="profile" src={fabio}/>
                        <Container>
                        <Grid container spacing={6}>
                            <Grid item xs={12}>
                                <div className="hero">
                                <Grid container>
                                    <Grid item xs={6}>
                                        <div className={'intro'}>
                                        <br/><br/><br/><br/><br/>
                                        <p>Hi I'm Fabio</p>
                                        <p>I design and develop mobile apps.</p>
                                        <p>Also 🏕️⛰️🌲☀️🏄🚴🍕🌴🏖️🌊</p>
                                        <p><br/>As a developer I understand the powers and the constraints of a mobile device. And with design, research and prototypes I discover user behaviors and create humane experiences that don’t need to be explained.</p>
                                        <br/>
                                        <p>I'm currently with the team at Smart Launcher building a completely new awesome set of widgets.
                                            <br/>
                                            Previously at <b>WooliesX</b> built the new mobile experience for the Everyday Rewards App and <b>SafetyCulture</b>.
                                        </p>
                                        </div>    
                                    </Grid>  
                                </Grid>
                                </div>
                            </Grid>
                            {items !=null ? 
                            
                            <div>
                                <Grid item xs={12}>
                                <div id="#projects" className={'intro'}>
                                <h2>Check out the <a className={'project-filter'} href="#projects" onClick={()=> toggleFocus(focus)} >{focus}</a> I worked on</h2>
                                </div>    
                                </Grid>
                            <Grid container className="projectSheet mt-3">
                                {items.map(item => 
                                    <Grid item xs={12} sm={12}>
                                    <div className="project">
                                            <Grid container direction={"row"} spacing={5}>  
                                                <Grid item xs={12} sm={3}> 
                                                    <p><div className="project-image" style={{backgroundImage:'url('+item.data().image+')'}}/></p>
                                                </Grid>
                                                <Grid item xs={12} sm={8}> 
                                                    <p className="project-name"><b>{item.data().title}</b></p>
                                                    <p>{item.data().body}</p>
                                                    {item.data().link ? <div className="project-link"><br></br><a className="growBit" target="_blank" href={item.data().link}>See more</a></div> : null}
                                                </Grid>
                                            </Grid>
                                    </div>    
                                </Grid>  )} 
                            </Grid>
                            </div>
                            
                            : <p>Loading projects...</p>}
                        </Grid>
                        </Container>
                        <p className={"socials"}>
                        <br></br><a href='https://twitter.com/fabiosend'><img src={twitter} /></a>
                        <br></br><a href='https://github.com/fabiosanto'><img src={git}/></a>
                        <br></br><a href='https://www.linkedin.com/in/fabiosanto/'><img src={link}/></a>
                        <br></br> <a href='https://medium.com/@fabiosanto'><img src={medium}/></a>
                    </p>
                    </body>
                    </div>)
}

export default Home;
