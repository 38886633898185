import * as React from "react"
import { Helmet } from "react-helmet"
import Home from "../components/Home"

function IndexPage(props) {

    return <div>
        <Helmet>
        <meta charSet="utf-8" />
        <title>Fabio Santo Mobile Product Engineer, design and development</title>
        <link rel="canonical" href="https://fabio.santo.dev" />
        </Helmet>
        <div className="index-body">
            {Home()}
        </div>
    </div>
}

export default IndexPage